import React from "react"
import SEO from "../components/utility/SEO"
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Logo from '../images/bond-logo.svg'
import Robot from '../images/coming-soon-robot.svg'
import Box from "@material-ui/core/Box";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GatsbyLink from "../components/utility/GatsbyLink";
import { graphql } from "gatsby";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: `100vh`,
    padding: theme.spacing(8),
    color: theme.palette.dark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    }
  },
  logo: {
    objectFit: 'contain',
    maxHeight: 80,
    height: 80,
    width: '100%'
  },
  image: {
    width: '100%',
    maxHeight: 416,
    objectFit: 'contain',
    marginBottom: theme.spacing(4)
  },
  typographyContainer: {
    width: '100%',
  },
  typography: {
    marginBottom: theme.spacing(4),
  }
}));

export default function ComingSoon({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!data || !data.prismicComingSoon) return '';
  const { data: pageData } = data.prismicComingSoon;

  return (
      <>
        <SEO title="Bond Goggles"/>
        <Box className={classes.root}>
          <img className={classes.logo} alt='' src={Logo}
               style={matches ? {objectPosition: 'center'} : {objectPosition: 'left'}}/>
          <img className={classes.image} alt='' src={Robot}/>
          <Box display={'flex'} justifyContent={matches ? 'center' : 'flex-end'}
               className={classes.typographyContainer}>
            <Box>
              <Typography className={classes.typography} variant='h2' color='inherit'>
                {pageData.message}
              </Typography>
              <GatsbyLink href={`mailto:${pageData.email}`}>
                <Typography variant='body1' color='inherit'>
                  {pageData.email}
                </Typography>
              </GatsbyLink>
            </Box>
          </Box>

        </Box>
      </>
  )
};

export const comingSoonQuery = graphql`
    query ComingSoon($id: String!) {
        prismicComingSoon(id: {eq: $id}) {
            data {
                message
                email
            }
        }
    }
`;
